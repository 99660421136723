import React from 'react';
import { graphql } from 'gatsby';


const WpPostTemplate = ({ data: { wpPost } }) => {
  const { title, content  } = wpPost;


  return (
    <section>
      <header class="entry-header alignwide">
        <h1 class="entry-title">{title}</h1>
      </header>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </section>
  );
};

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
    }
  }
`;

//      customFields {
//	fieldGroupName
//	name
//	tel
//      }

export default WpPostTemplate;